<template>
  <div class="content-box-padding">
    <img
      src="~assets/image/about_us/企业文化1.png"
      alt="企业文化1"
      class="img-center"
      style="margin-bottom: 65px"
    />
    <img
      src="~assets/image/about_us/企业文化2.png"
      alt="企业文化2"
      class="img-center"
    />
  </div>
</template>
